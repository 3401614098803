// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("packs/bootstrap-datetimepicker.js")
require("packs/JsBarcode.all.min.js")

import jquery from 'jquery';
window.$ = window.jquery = jquery;

import "bootstrap";
import "../stylesheets/application"  // <- Add this line
 

import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
window.tippy = tippy;
 
const feather = require("feather-icons");
 
document.addEventListener("turbolinks:load", () => {
  //$('[data-toggle="tooltip"]').tooltip()
  //$('[data-toggle="popover"]').popover()
  feather.replace();
})
 
